import React from 'react';
import './App.css';

const Disciplines = () => {
  return (
    <div className="home-container">
      <h1>My Disciplines</h1>
      <p>I believe in people, in building bridges, and in the power of meaningful conversations.</p>
      <p>I believe in win-win deals, creating added value instead of zero-sum games.</p>
      <p>I believe in the strength of family and the importance of lifelong friendships. Play long-term games, not short-term gains.</p>
      <p>I believe in a healthy and conscious lifestyle, where mindful living leads to a happy, purposeful life. I’m trying to be not self-destructive.</p>
      <p>I believe in technology as one of the potential tools to solve the world's problems and help people. I am a techno-optimist, but my mission is to promote responsible development and emphasize the urgency of education in this field.</p>
      <p>I believe in Hungary, my country, and in the numerous outstanding talents it has fostered / will foster.</p>
      <p>I believe in a shared energy that connects us all.</p>
    </div>
  );
};

export default Disciplines;
