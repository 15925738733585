import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import Disciplines from './Disciplines';
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <header>
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/disciplines">My Disciplines</Link></li>
              <li><a href="mailto:Norbi.gaal.721@gmail.com">Contact</a></li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/disciplines" element={<Disciplines />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
