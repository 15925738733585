import React from 'react';
import './App.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Norbi Gaál</h1>
      <div className="social-links">
        <a href="https://x.com/norbigaal" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://www.linkedin.com/in/norbigaal/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://substack.com/home" target="_blank" rel="noopener noreferrer">Substack</a>
      </div>
      <section className="about-me">
        <p>I have been focused on developing a series of digital products, managing companies, and building expert communities. I am a techno-optimist,passionate about early-stage investments and incubation.</p>
        <p>From 2018, we built up Hungary's first Fintech incubation/digital product house called <a href="https://www.fintechlab.hu" target="_blank" rel="noopener noreferrer">MKB Fintechlab</a>. Based on these results, we started to rebuild Hungary's second-biggest bank from the merger of three banks - This is the story of <a href="https://www.thoughtmachine.net/press-releases/magyar-bankholding-selects-thought-machine-to-launch-a-digital-first-bank" target="_blank" rel="noopener noreferrer">Hungarian Bankholding</a>.</p>
        <p>From 2022, I was the CEO of one of the MBH ventures called <a href="https://bupa.hu/bupa" target="_blank" rel="noopener noreferrer">BUPA</a>, where our goal was to build a one-stop digital shop for MSMEs. We introduced a new way of thinking about platforms and ecosystem thinking to the Hungarian market. Within 9 months, we created the strategy and also developed & launched the MVP. During my leadership, the platform reached 5000+ new users within 8 months.</p>
      </section>
      <section className="communities">
        <h2>Communities</h2>
        <p>2014: Founded Hungarian UI/UX Designers to bolster the Hungarian design community.</p>
        <p>2016: Established <a href="https://cognitiveuxd.com" target="_blank" rel="noopener noreferrer">CognitiveUXD.com</a>, driven by my passion for the intersection of UX and cognitive psychology.</p>
      </section>
      <section className="writing">
        <h2>Writing</h2>
        <p>I'm also writing on <a href="https://substack.com/home" target="_blank" rel="noopener noreferrer">Substack</a> and <a href="https://medium.com/@norbigaal" target="_blank" rel="noopener noreferrer">Medium</a>, sharing insights and experiences.</p>
      </section>
      <section className="investment">
        <h2>Investment</h2>
        <p>In collaboration with friends and partners, I've co-founded <a href="https://www.first-principle.cc" target="_blank" rel="noopener noreferrer">First Principle Club</a> and FPC Early-stage Incubator to support Hungarian talents and startups.</p>
      </section>
    </div>
  );
};

export default Home;
